import React from "react";
import PropTypes from "prop-types";

class Stat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {contentArray: [...this.props.content]};
  }

  render() {

    let list = this.state.contentArray.map((value, index) => {
      return value ? <div key={index}>{value}</div> : null
    });

    return (
      <div className="stat">
        <h3>{this.props.title}</h3>
        <div className="content">
          {list}
        </div>
      </div>
    );
  }
}

export default class PortInfo extends React.Component {

  changeTab(e) {
    e.preventDefault();
    this.setState({activeTabId: e.target.id});
  }

  render() {

    return (
      <div className="port-info">
        <div className="address">
          <h3>Address</h3>
          <address>{this.props.fullAddress}</address>
        </div>
        <div className="phone-fax-group">
          <div className="phone">
            <h3>Phone</h3>
            <a href={"tel:" + this.props.phoneNumber}>{this.props.phoneNumber}</a>
          </div>
          <div className="fax">
            <h3>Fax</h3>
            <a href={"tel:" + this.props.fax}>{this.props.fax}</a>
          </div>
        </div>
        <div className="extra">
          {/*text*/}
          <Stat title="Terminal Operator" content={this.props.terminalOperator}/>
          <Stat title="Cargoes Served" content={this.props.cargoesServed}/>

          {/*numbers*/}
          <Stat title="Total Terminal Area"
                content={[this.props.totalTerminalAreaImperial, this.props.totalTerminalAreaMetric]}/>
          <Stat title="Length of Berths"
                content={[this.props.lengthOfBerthsImperial, this.props.lengthOfBerthsMetric]}/>
          <Stat title="Wharf Height" content={[this.props.wharfHeightImperial, this.props.wharfHeightMetric]}/>
          <Stat title="Open Storage Area"
                content={[this.props.openStorageAreaImperial, this.props.openStorageAreaMetric]}/>
          <Stat title="Design Depth of Water"
                content={[this.props.designDepthOfWaterImperial, this.props.designDepthOfWaterMetric]}/>

          {/*more text*/}
          <Stat title="Special Equipment & Facilities" content={this.props.specialEquipmentAndFacilities}/>
        </div>
      </div>
    );
  }
}