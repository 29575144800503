import React from "react";
import Tenant from "./tenant";
import PortInfo from "./port-info";

var classNames = require('classnames');

export default class PierDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {activeTabId: 'tenants-tab'};

    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(e) {
    e.preventDefault();
    this.setState({activeTabId: e.target.id});
  }

  render() {

    let tenants = <p>No tenants found!</p>;

    if (this.props.details && this.props.details.info && Array.isArray(this.props.details.info.tenants) && this.props.details.info.tenants.length) {
      tenants = this.props.details.info.tenants.map((tenant, index) => <Tenant {...tenant} key={'tenant-' + index}/>);
    } else if (Array.isArray(this.props.tenants) && this.props.tenants.length) {
      tenants = this.props.tenants.map((tenant, index) => <Tenant {...tenant} key={'tenant-' + index}/>);
    }

    let title = this.props.title ? this.props.title : "Pier " + this.props.details.id;

    return (
      <div className="map-overlay more-details">
        <div className="pier-header">
          <h2>{title}</h2>
          <svg className="icon icon--nav-close" onClick={this.props.closePanelCallback}>
            <use xlinkHref="#icon-close"/>
          </svg>
        </div>
        <ul className="options">
          <li><a id="tenants-tab" href="#notused" onClick={this.changeTab}
                 className={classNames({'active': this.state.activeTabId === 'tenants-tab'})}>Facilities</a></li>
        </ul>
        <div className={classNames({
          'tab-content': true,
          'hide': this.state.activeTabId !== 'tenants-tab'
        })}>
          {tenants}
        </div>
      </div>
    );
  }
}
