import React from "react";

const fetch = require('isomorphic-fetch')

export default class MapArticle extends React.Component {

  constructor(props) {
    super(props);

    //this.state = {post: null};
  }

  // componentDidMount() {
  //   fetch("https://thehelm.polb.com/wp-json/wp/v2/posts/" + this.props.projectArticleID)
  //     .then(response => response.json())
  //     .then(post => {
  //       this.setState({post});
  //     })
  //     .catch(e => console.log(e));
  // }

  render() {
    console.log("Here arrive project info " + this.props.projectInfo)
    if (!this.props.projectInfo) {
      return <div className="map-overlay more-details map-article">
        <div className="close-panel">
          <svg className="icon icon--nav-close" onClick={this.props.closePanelCallback}>
            <use xlinkHref="#icon-close"/>
          </svg>
        </div>
        <div className="map-article-body">
          <p>Getting article...</p>
        </div>
      </div>
    }

    return (
      <div className="map-overlay more-details map-article">
        <div className="close-panel">
          <svg className="icon icon--nav-close" onClick={this.props.closePanelCallback}>
            <use xlinkHref="#icon-close"/>
          </svg>
        </div>
        <div className="map-article-body">
          <h1>{this.props.projectInfo.heading}</h1>
          <hr/>
          {this.props.projectInfo.image &&
          <img src={this.props.projectInfo.image.sizes.medium} alt={this.props.projectInfo.image.title}/>}
          <p>
            {this.props.projectInfo.excerpt}
          </p>
          <div className="read-more">
            <div className="rhombus"/>
            <a href={this.props.projectInfo.url.url} target="_blank" rel="noopener noreferrer">{this.props.projectInfo.url.title}</a>
          </div>
        </div>
      </div>
    );
  }
}
