import React from "react";

const fetch = require('isomorphic-fetch')

export default class BerthDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.berthInfo) {
      return <div className="map-overlay more-details berth-info">
        <div className="close-panel">
          <svg className="icon icon--nav-close" onClick={this.props.closePanelCallback}>
            <use xlinkHref="#icon-close" />
          </svg>
        </div>
        <div className="map-article-body">
          <p>Getting article...</p>
        </div>
      </div>
    }

    return (
      <div className="map-overlay more-details berth-info">
        <div className="berth-header">
          <h2>{this.props.berthInfo.title}</h2>
          <svg className="icon icon--nav-close" onClick={this.props.closePanelCallback}>
            <use xlinkHref="#icon-close" />
          </svg>
        </div>
        <div className="berth-body">
          <h2>Temporary Berth Assignment</h2>

          <ul>
            <li>
              <p>Electricity:</p>
              <span>{this.props.berthInfo.electricity}</span>
            </li>
            <li>
              <p>Fresh Water:</p>
              <span>{this.props.berthInfo.fresh_water}</span>
            </li>
            <li>
              <p>Fender System:</p>
              <span>{this.props.berthInfo.fender_system}</span>
            </li>
            <li>
              <p>Landside Access:</p>
              <span>{this.props.berthInfo.landside_access}</span>
            </li>
            {/* <li>
              <p>Approx. Water Depth:</p>
              <span>{this.props.berthInfo.water_depth}</span>
            </li> */}
          </ul>

          <div className="additional-text" dangerouslySetInnerHTML={{ __html: this.props.berthInfo.additional_text }} />

          <div className="read-more">
            <div className="rhombus" />
            <a href={this.props.berthInfo.url.url} target="_blank" rel="noopener noreferrer">{this.props.berthInfo.url.title}</a>
          </div>
        </div>
      </div>
    );
  }
}
