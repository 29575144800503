import React from "react";
import PropTypes from "prop-types";

var classNames = require('classnames');

export default class Tenant extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showDetails: false };
  }

  componentDidMount() {


  }

  render() {
    let more_details = this.props.more_details.map((current, index) => {
      let anchorLink = null;
      let anchorTag = null;
      let anchorText = null;

      // console.log(current);

      // Check if value is a link or not. Due to the way data is being passed links render as strings, so it is necessary to transform them into actual "a" tags
      if (current.value[0] === "<" && current.value[1] === "a") {
        // Get the href of the a tag
        anchorLink = current.value.split(/"/)[1];
        anchorText = current.value.substring(current.value.indexOf('>') + 1, current.value.length - 4);
        let splitLink = anchorLink.substring(0, 32);

        // Imperfect method of identifying what type of link it is. 
        // If it is a internal site link, simply form a local path. 
        // If it is a download link, make an anchor tag that does not open new tab.
        // For all other types of links, path is not modified and link opens new tab.
        if (splitLink === "https://polb-stage.stellaragency") {
          let urlPath = anchorLink.substring(36);
          anchorTag = <a href={urlPath} target="_blank" rel="noopener noreferrer">{anchorText}</a>
        } else if (splitLink === "https://polb.wpengine.com/downlo") {
          anchorTag = <a href={anchorLink} rel="noopener noreferrer" className="tenant-download-link">{anchorText}</a>
        } else {
          anchorTag = <a href={anchorLink} target="_blank" rel="noopener noreferrer">{anchorText}</a>
        }
      }

      return (
        <div className="tenant-more-details" key={'tenant-details' + index}>
          <b>{current.label}</b><br />
          {
            anchorLink ?
              <span>{anchorTag}</span>
              :
              <p>{current.value}</p>
          }
        </div>
      )
    });

    // let more_details = this.props.more_details.map((current, index) => {
    //   console.log(current.value);
    //   console.log(current.value.split(/"/)[1]);
    //   return (
    //     <div className="tenant-more-details" key={'tenant-details' + index}>
    //       <b>{current.label}</b><br />
    //       {current.value}
    //     </div>
    //   );
    // });

    return (
      <div className="tenant">
        <h4>{this.props.terminal_operator}</h4>
        <div className="tenant--details">
          <div className="basic">
            <div className="tenant--details__contact">
              <address>
                {this.props.address_line_1}<br />
                {this.props.address_line_2}
              </address>
              {this.props.phone && <a href={"tel: " + this.props.phone}>{this.props.phone}</a>}
            </div>
            <div className="tenant--details__cargoType">
              <b>Cargo Type</b><br />
              {this.props.cargo_type}
            </div>
          </div>
          <div className="tenant-more-info">
            <button onClick={event => {
              event.preventDefault();
              this.setState({ showDetails: !this.state.showDetails })
            }}>More Info
            </button>
          </div>
          <div className={classNames({ 'tenant-more-details-box': true, 'expanded': this.state.showDetails })}>
            {
              this.props.terminal_operator_web_site &&
              <div className="website">
                <b>Website</b><br />
                <a target="_blank" href={this.props.terminal_operator_web_site} rel="noopener noreferrer">{this.props.terminal_operator_web_site}</a>
              </div>
            }
            {more_details}
          </div>
        </div>
      </div>
    );
  }
}

Tenant.propTypes = {};

Tenant.defaultProps = {};

